import React from "react";
import { Box, Typography } from "@mui/material";
const IndustryCollabration = () => {
  return (
    <Box sx={{ margin: "60px 80px" }}>
      <Typography variant='h6'                                     
      sx={{
                                      fontSize: 24,
                                      lineHeight: "26px",
                                      fontWeight: "bold",
                                    }}>
        Industry Collaboration
      </Typography>
      <Typography mt={1}      
      sx={{
                                      fontSize: 18,
                                      lineHeight: "26px",
                                      fontWeight: 400,
                                    }}>
        Gentech aims to build a strong research community and hopes to build a
        bridge between innovations and development.
      </Typography>
      <Typography
            sx={{
              fontSize: 18,
              lineHeight: "26px",
              fontWeight: 400,
            }}>
        {" "}
        We provide all the below mentioned services and more.
      </Typography>
      <ul>
        <li>Collaborative Research & Development</li>
        <li>MOUs with Institutes</li>
        <li>Workshops & Conferences</li>
        <li>Faculty Development Programs & CMEs</li>
        <li>Technical Training</li>
        <li>Industry experience and internships</li>
      </ul>
      <Typography>
        {" "}
        Feel free to contact us and we would love to hear about
        more collaborations
      </Typography>
    </Box>
  );
};

export default IndustryCollabration;
