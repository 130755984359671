import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import IndustryCollabration from './pages/product/IndustryCollabration';

// Lazy-loaded components
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const ProductPage = lazy(() => import('./pages/product/ProductPage'));
const Research = lazy(() => import('./pages/Research'));
const Contact = lazy(() => import('./pages/Contact'));
const GetAQuote = lazy(() => import('./pages/GetAQuote'));
const RequestInformation = lazy(() => import('./pages/RequestInformation'));
const GetDemo = lazy(() => import('./pages/GetDemo'));
const TalkToUs = lazy(() => import('./pages/TalkToUs'));
const BecomeOurPatners = lazy(() => import('./pages/BecomeOurPatners'));
const OurCompany = lazy(() => import('./pages/OurCompany'));
const OurPatners = lazy(() => import('./pages/OurPatners'));
const Events = lazy(() => import('./pages/Events'));
const Navbar = lazy(() => import('./components/Navbar'));
const Footer = lazy(() => import('./components/Footer'));
const SemiProductDetails = lazy(() => import('./pages/product/SemiProductDetails'));
const OneProduct = lazy(() => import('./pages/product/OneProduct'));

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Suspense fallback={<div><LinearProgress /></div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/product/:productID" element={<ProductPage />} />
          <Route path="/products/industry_collabration" element={<IndustryCollabration />} />
          <Route path='/:category_id/:sub_category_id/' element={<SemiProductDetails />} />
          <Route path="/research" element={<Research />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/get-quote" element={<GetAQuote />} />
          <Route path="/requested_information" element={<RequestInformation />} />
          <Route path="/get_demo" element={<GetDemo />} />
          <Route path="/talk_us" element={<TalkToUs />} />
          <Route path="/become_patners" element={<BecomeOurPatners />} />
          <Route path="/our_company" element={<OurCompany />} />
          <Route path="/our_patners" element={<OurPatners />} />
          <Route path="/events" element={<Events />} />
          <Route path="/:category_id/:sub_category_id/:product_id" element={<OneProduct />} />
        </Routes>
      <Footer />
      <Toaster />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
